<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t("faq_categories.management") }}</li>
            <li>{{ $t("general.category_list") }}</li>

          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span> {{ $t("faq_categories.management") }} </span>
          </h2>
        </div>
        <div class="section-header-right">
          <router-link :to="{ name: 'company.faq_categories.add' }">
            <button class="btn btn-default" type="button" aria-expanded="false">
              <i class="uil-plus"></i> {{ $t("faq_categories.add") }}
            </button>
          </router-link>
        </div>
      </div>

      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <!-- <div>
              <a href="#" class="btn btn-icon btn-hover btn-circle" aria-expanded="false">
                <i class="uil-search"></i>
              </a>
              <div class="uk-drop" uk-drop="mode: hover; pos: right-center; offset: 0" style="left: 91px; top: 159.297px;">
                <form class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" :placeholder="$t('general.search')" autofocus="" v-model="filter.search">
                </form>
              </div>
            </div> -->
            <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                  :placeholder="$t('general.search')" />
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="#" class="btn btn-icon btn-hover  btn-circle" :uk-tooltip="$t('filters.sort_list')" title=""
                aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('name', 'asc')">
                    <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                  </li>
                  <li @click="sortOrder('name', 'desc')">
                    <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'asc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_asc") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'desc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_desc") }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t("faq_categories.title") }}</th>
                <th scope="col">{{ $t("general.added_at") }}</th>
                <th scope="col">{{ $t("general.status") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <!--user item-->
              <tr v-for="faq_category in formattedItem">
                <th scope="row">
                  <div class="media align-items-center">
                    <div class="media-body ml-4">
                      <router-link :to="{
                        name: 'company.faq_categories.edit',
                        params: { id: faq_category.id },
                      }" class="name h6 mb-0 text-sm">
                        {{ faq_category.name }}
                      </router-link>
                    </div>
                  </div>
                </th>
                <td>{{ faq_category.created_at }}</td>
                <td>{{ faq_category.status }}</td>
                <td class="text-right">
                  <div class="actions ml-3">
                    <router-link :to="{
                      name: 'company.faq_categories.edit',
                      params: { id: faq_category.id },
                    }" class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('faq_categories.edit')">
                      <i class="uil-edit "></i>
                    </router-link>
                    <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Sil"
                      title="" @click="removeFaq(faq_category.id)" aria-expanded="false">
                      <i class="uil-minus-circle text-danger"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination"
          @changePage="setPage($event)"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";
import faq_category_module, {
  BASE_URL as FAQ_CATEGORY_BASE_URL,
  MODULE_NAME as FAQ_CATEGORY_MODULE_NAME,
  ERROR as FAQ_CATEGORY_ERROR,
  ITEMS as FAQ_CATEGORY_ITEMS,
  GET_ITEMS as FAQ_CATEGORY_GET_ITEMS,
  DELETE_ITEM as FAQ_CATEGORY_DELETE_ITEM,
} from "@/core/services/store/faq_category.module";
import moment from "moment";
import {confirmDialogDelete} from "@/utils/confirmDialog";
export default {
  name: "FaqList",
  components: { DataTable, Pagination },
  data() {
    return {
      status_id: null,
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      filter: {
        search: null,
        orderBy: "id",
        sort: "desc",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(FAQ_CATEGORY_MODULE_NAME, faq_category_module);
  },
  computed: {
    items: {
      get() {
        return store.getters[
          FAQ_CATEGORY_MODULE_NAME + "/" + FAQ_CATEGORY_ITEMS
        ];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[
          FAQ_CATEGORY_MODULE_NAME + "/" + FAQ_CATEGORY_ERROR
        ];
      },
      set(value) { },
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;
      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          name: currentItem.description.title,
          status: currentItem.status
            ? this.$t("general.active")
            : this.$t("general.passive"),
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });

      return results;
    },
  },
  methods: {
    getFaqResult() {
      let self = this;
      this.$store.dispatch(
        FAQ_CATEGORY_MODULE_NAME + "/" + FAQ_CATEGORY_GET_ITEMS,
        {
          url: FAQ_CATEGORY_BASE_URL,
          filters: {
            like: self.filter.search,
            page: self.page,
            per_page: self.per_page,
            sort: self.filter.sort,
            order_by: self.filter.orderBy,
          },
        }
      );
    },
    async removeFaq(id) {
      const isConfirmed = await confirmDialogDelete({context: this});

      if (isConfirmed) {
        this.$store
            .dispatch(FAQ_CATEGORY_MODULE_NAME + "/" + FAQ_CATEGORY_DELETE_ITEM, {
              url: FAQ_CATEGORY_BASE_URL + "/" + id,
            })
            .then(() => {
              if (!this.error) {
                this.successMessage = this.$t("general.successfully_deleted");
                this.getFaqResult();
              } else this.errorMessages.push(this.error);
            });
      }

    },
    sortOrder(orderBy, sort, is_user = true) {
      let self = this;
      self.page = 1;
      self.filter.orderBy = orderBy;
      self.filter.sort = sort;

      this.getFaqResult();
    },
    setPage(page, is_user = true) {
      let self = this;
      self.page = page;
      this.getFaqResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getFaqResult();
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getFaqResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
